import { tns } from 'tiny-slider/src/tiny-slider';

class IndexPage{
    constructor()
    {
        this.sliderProductData = '[data-products-slick]'
        this.slider = null
        this.aboutButtonReadMo = '[data-about-button-read-more]'
        this.aboutBlock = '[data-about-block]'
        this.init()
    }

    init()
    {
        const  elementProductSlider = document.querySelector(this.sliderProductData)
        if (elementProductSlider) {
            this.initProductSlider(this.sliderProductData);
        }

        const elementAboutButtonReadMo = document.querySelector(this.aboutButtonReadMo)
        const elementAboutBlock = document.querySelector(this.aboutBlock)
        if (elementAboutButtonReadMo && elementAboutBlock) {
            this.addEventAbout(elementAboutButtonReadMo, elementAboutBlock)
        }

    }

    initProductSlider(elementProductSlider)
    {
        this.slider = tns({
            container: elementProductSlider,
            gutter: 20,
            slideBy: "page",
            mouseDrag: true,
            swipeAngle: false,
            speed: 400,
            autoplay: true,
            controls: true, // Включаем кастомные кнопки
            prevButton: document.querySelector('[data-controls="prev"]'),
            nextButton: document.querySelector('[data-controls="next"]'),
            responsive: {
                1: {
                    items: 1
                },
                500: {
                    items: 2
                },
                1000: {
                    items: 3
                }
            }
        });
    }
    addEventAbout(elementAboutButtonReadMo, elementAboutBlock)
    {
        elementAboutButtonReadMo.addEventListener('click', (e) => {
            elementAboutBlock.classList.toggle('_open')
            elementAboutButtonReadMo.classList.add('_hidden')
        })
    }
}
document.addEventListener('DOMContentMutated',() => {
    new IndexPage();
});

document.addEventListener('DOMContentLoaded',() => {
    new IndexPage();
});
