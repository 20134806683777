require('../scss/app.scss');

window.noZensmooth = true;
import sizes from "../scss/_settings/_settings.scss";
window.sizes = sizes;

require('./common/goal.js');
require('./common/js_validation.js');
require('./header.js');

require('./page/index.js');
require('./page/catalog.js');
require('./page/product.js');
