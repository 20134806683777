import { tns } from 'tiny-slider/src/tiny-slider';
import debounce from 'debounce';

class Catalog{
    constructor()
    {
        this.sliderCategoriesData = '[data-categories-slick]';
        this.slider = null;
        this.init();
    }

    init()
    {
        const elementCategoriesSlider = document.querySelector(this.sliderCategoriesData);
        if (elementCategoriesSlider) {
            this.checkWindowSizeAndInitSlider(elementCategoriesSlider);
            window.onresize = debounce(() => this.checkWindowSizeAndInitSlider(elementCategoriesSlider), 200);
        }
    }

    checkWindowSizeAndInitSlider(elementCategoriesSlider)
    {
        const windowWidth = window.innerWidth;
        if (windowWidth < 769) {
            if (!this.slider) {
                this.initCategorySlider(elementCategoriesSlider);
            }
        } else {
            this.destroySlider();
        }
    }

    initCategorySlider(elementProductSlider)
    {
        if (this.slider !== null) {
            this.destroySlider();
        }
        setTimeout(() => {
            this.slider = tns({
                container: elementProductSlider,
                gutter: 20,
                slideBy: "page",
                mouseDrag: true,
                swipeAngle: false,
                speed: 1000,
                autoplay: true,
                controls: false,
                prevButton: document.querySelector('[data-controls="prev"]'),
                nextButton: document.querySelector('[data-controls="next"]'),
                responsive: {
                    1: {
                        items: 3
                    },
                }
            });
        }, 400);
    }

    destroySlider()
    {
        if (this.slider !== null) {
            this.slider.destroy();
            this.slider = null;
        }
    }
}

document.addEventListener('DOMContentMutated', () => {
    new Catalog();
});

document.addEventListener('DOMContentLoaded', () => {
    new Catalog();
});
