import { tns } from 'tiny-slider/src/tiny-slider';

class Product{
    constructor()
    {
        this.sliderProductData = '[data-product-slider]'
        this.slider = null
        this.init()
    }

    init()
    {
        const  elementProductSlider = document.querySelector(this.sliderProductData)
        if (elementProductSlider) {
            this.initProductSlider(this.sliderProductData);
        }
    }

    initProductSlider(elementProductSlider)
    {
        this.slider = tns({
            container: elementProductSlider,
            gutter: 20,
            slideBy: "page",
            mouseDrag: true,
            swipeAngle: false,
            speed: 400,
            autoplay: false,
            controls: false, // Включаем кастомные кнопки
            prevButton: document.querySelector('[data-controls="prev"]'),
            nextButton: document.querySelector('[data-controls="next"]'),
            responsive: {
                1: {
                    items: 1
                },
            }
        });
    }
}
document.addEventListener('DOMContentMutated',() => {
    new Product();
});

document.addEventListener('DOMContentLoaded',() => {
    new Product();
});
